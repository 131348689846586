@import "vars";
@import "settings";
@import "base";

.g-link-abc {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.section {
    padding: 60px 0;
    color: #626366;
    background-color: #fff;
}

.section__title {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 20px;
    color: #333;
}

.section__text{
    margin-bottom: 30px;
}

@media (max-width: 576px) {
    .section__title{
        font-size: 25px;
    }
}