html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a{
    color: inherit;
    text-decoration: none;
}

img{
    max-width: 100%;
}

.list-reset{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.btn-reset{
    padding: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.fixed{
    position: fixed;
    top: var(--grid-grap);
    right: 0;
}
