// COLORS
:root {
  --accent-color: #006EC7;
  --dark-color: #2F2222;
  --accent-color2: #76d700;
  --color-black: #000;
  --color-white: #fff;
}

$accent-color: grey; 
$accent-color2: #76d700; 